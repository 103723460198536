@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  min-height: 100vh;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(to top, #000 6%, #000 70%); /* background: linear-gradient(to top, #9a8200 6%, #000 70%); */
  display: flex;
  flex-direction: column;
  color: white;
}

.Desktop .App {
  min-height: 85vh;
}

.Desktop .Header {
  min-height: 15vh;
}